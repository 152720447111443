/*=================================================================
  About us section
==================================================================*/

.about {
  padding:100px 0;
  background: $light;
  
  .btn-about {
    background: $primary-color;
  }
  h4 {
    margin-top: 30px;
  }
  .feature-list {
    margin-top: 10px;
    li {
      width: 48%;
      display: inline-block;
      color: #666;
      font-size: 13px;
      font-family: $secondary-font;
      margin-bottom: 5px;
      font-weight: 600;
      i {
        color: $primary-color;
        margin-right: 8px;
      }
    }
  }
}


/*=================================================================
  About us 2 section
==================================================================*/
.about-2 {
  background: #F7F7F7;
  .checklist {
    padding-left: 30px;
    li {
      position: relative;
      margin-bottom: 15px;
      font-size: 16px;
      color: #777;
      font-family: $primary-font;
      &:before {
        position: absolute;
        margin-right: 12px;
        margin-left: -45px;
        content: "\f375";
        font-family: $icon-font;
        opacity: 1;
        border-radius: 50%;
        padding: 2px 8px;
        font-size: 20px;
      }
    }
  }
}
.hero-area {
  background-image: url('../images/slider/slider-bg-1.jpg');
  background-size: cover;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.63);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .block {
    color: $light;

    h1 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 20px;
      text-transform: uppercase;
    }

    p {
      color: $light;
      width: 50%;
      margin-bottom: 20px
    }

    .btn-main {
      margin-right: 8px;

      &:hover {
        opacity: .8;
      }
    }
  }
}

.hero-area-video {
  height: 100vh;
  position: inherit !important;
  display: flex;
  justify-content: center;
  align-items: center;

  .block {
    color: $light;
    text-align: center;

    h1 {
      font-size: 60px;
      font-weight: 700;
      margin-bottom: 20px;
    }

    p {
      color: $light;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 3px;
      margin-bottom: 20px
    }

    .btn-main {
      margin-top: 20px;
    }
  }
}

.dark-bg {
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
}


.slider-item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  h1{
    color: #fff;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: 2px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    color: #fff;
    margin-bottom: 20px;
  }
}

.hero-slider {
  overflow-x: hidden;

  .prevArrow {
    left: -100px;
  }

  .nextArrow {
    right: -100px;
  }

  &:hover {
    .prevArrow {
      left: 20px;
    }

    .nextArrow {
      right: 20px;
    }
  }
}

/* slick style */
.slick-slide {
  outline: 0;
}

.slick-slide img {
  display: unset;
}

/* slick arrows */
.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  height: 70px;
  width: 70px;
  background: rgba($color: $primary-color, $alpha: .5);
  color: #fff;
  border: 0;
  line-height: 70px;
  font-size: 35px;
  transition: .2s ease;
  border-radius: 50%;

  &::before {
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    transition: .2s ease;
  }

  &::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 25px;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    transition: .2s ease;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    background: $primary-color;
  }
}

.prevArrow {
  left: 0px;

  &::before {
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    right: 35px;
  }

  &::after {
    right: 20px;
  }
}

.nextArrow {
  right: 0px;

  &::before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    left: 35px;
  }

  &::after {
    left: 20px;
  }
}

/* /slick arrows */